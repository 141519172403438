
import { useState } from "react";
import "./header.css";
import headerLeftIcons from "../../../assets/icons/headerLeftIcons.png";
import francais from "../../../assets/images/francais.png";
import francais1 from "../../../assets/images/francais1.png";
import anglais from "../../../assets/images/anglais.png";
import tchouta from "../../../assets/images/tchouta.jpg";
import down from "../../../assets/icons/down.png";
import chevron from "../../../assets/icons/chevron.png";
import comptes from "../../../assets/icons/comptes.png";
import eyesOpen from "../../../assets/icons/eyesOpen.png";
import eyesLock from "../../../assets/icons/eyesLock.png";
import circleNike from "../../../assets/icons/circleNike.png";
import deconnecter from "../../../assets/icons/deconnecter.png";
import { useNavigate } from "react-router-dom";
import { fetchData } from "../../../assets/helpers/fetchData";
import { HeaderForm } from "../../repeatableComponents/atomes/headerForm/HeaderForm";
import { snackbbar } from "../../../assets/helpers/snackbar/snackBar";

export const Header = () => {
    const [etat, setEtat] = useState(false);
    const [etatMasque, setEtatMasque] = useState(false);
    const [checkPassword, setCheckPassword] = useState(false);
    const [checkForm, setCheckForm] = useState(false);
    const [email, setEmail] = useState("");
    const [etatEyes, setEtateyes] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [compte, setCompte] = useState(false);
    const [loading, setLoading] = useState(false);
    const [language, setLanguage] = useState(localStorage.getItem('language') || 'fr'); // Définit la langue par défaut
    const token = localStorage.getItem("token");
    const navigate = useNavigate();
    const changeLanguage = () => {
        setEtat(!etat);
    };

    const changeDeconnexion = () => {
        setCompte(!compte);
    };

    const handleClickOutside = (event) => {
        if (!event.target.closest(".header_right_left") && !event.target.closest(".header_right_right")) {
            setEtat(false);
            setCompte(false);
        }
    };

    const selectLanguage = (lang) => {
        setLanguage(lang);
        localStorage.setItem('language', lang); // Sauvegarde la langue sélectionnée dans le localStorage
        window.dispatchEvent(new Event("localStorageLanguage"));
        setEtat(false); // Ferme le menu de sélection de langue
    };
    const logout = () => {
        localStorage.removeItem("token");
        navigate("/");
    };
    const checkProfil = () => {
        setEtatMasque(true)
        setCheckForm(true)
    }
    const closePopup = () => {
        setEtatMasque(false)
    };
    const changesEyes = ()=>{
        setEtateyes(!etatEyes)
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        const fields = [
            { name: 'Nom', value: firstName },
            { name: 'Prenom', value: lastName },
            { name: 'email', value: email },
        ];

        for (let i = 0; i < fields.length; i++) {
            if (!fields[i].value) {
                const errorMessageForm = `Veuillez remplir le champ: ${fields[i].name}`
                return snackbbar(document.querySelector("#body"), circleNike, errorMessageForm, 3000);

            }
        }
        setCheckForm(false)
        setCheckPassword(true)
        // const data = {
        //     code:code,
        //     name: country,
           
        // }
       
        // try {

        //     setLoading(true);
        //     const result = await fetchData("countries", "POST", data, token)
        //     if (result.status === 201) {
        //          snackbbar(document.querySelector("#body"), circleNike,"pays crée avec succès", 2000);
                 
        //     }
        // } catch (error) {
        //     console.error(error.message);

        // } finally {
        //     setLoading(false);
        // }
}

    return (
        <div className="header" onClick={handleClickOutside}>
            <img src={headerLeftIcons} alt="" className="header_left_img" />
            <div className="header_right">
                <div className="header_right_left">
                    <img src={language === 'fr' ? francais : anglais} alt="" />
                    <div className="parent_icons" onClick={changeLanguage}>
                        <span>{language === 'fr' ? 'Français' : 'Anglais'}</span>
                        <img src={down} alt="" />
                    </div>
                </div>
                {
                    etat && (
                        <div className="change_language">
                            <span className="change_language_span">Sélectionner la langue</span>
                            <div className="sous_change_language">
                                <div onClick={() => selectLanguage('en')}>
                                    <img src={anglais} alt="" className="language_img" />
                                    <span>Anglais</span>
                                </div>
                                <div onClick={() => selectLanguage('fr')}>
                                    <img src={francais1} alt="" className="language_img" />
                                    <span>Français</span>
                                </div>
                            </div>
                        </div>
                    )
                }
                {
                    etatMasque && <div className="popup_wrapper">
                        <div id="masque"></div>
                       { checkForm && <form id="answer_form" onSubmit={handleSubmit}>
                            <HeaderForm
                                text=""
                                closePopup={closePopup} />
                            <div className="tiretsBottom">
                                <span className="infosComptes">Mes Informations</span>
                                <span className="infosSecurite">Sécurite</span>
                            </div>
                            {/* <img src={francais} className="photoInfos" alt="photoInfos" /> */}
                            <div className="children_form">
                                <label htmlFor="">Nom</label>
                                <input type="text" placeholder="yoko" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                            </div>
                            <div className="children_form">
                                <label htmlFor="">Prenom</label>
                                <input type="text" placeholder="Mike" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                            </div>
                            <div className="children_form">
                                <label htmlFor="">Adresse Mail</label>
                                <input type="text" placeholder="sm@gmail.com" value={email} onChange={(e) => setEmail(e.target.value)} />
                            </div>
                            <div className="parent_button_tribunal1">
                                <span></span>
                                {loading ? (
                                    <button className="button_tribunal_black">En cours ...</button>
                                ) : (
                                    <button className="button_tribunal" type="submit">Enregistrer les modifications</button>
                                )

                                }
                            </div>
                        </form>}
                    </div>
                }
                {
                    checkPassword && <div className="popup_wrapper">
                        <form id="answer_form" onSubmit={handleSubmit}>
                            <HeaderForm
                                text=""
                                closePopup={closePopup} />
                            <div className="tiretsBottom">
                                <span className="infosSecurite">Mes Informations</span>
                                <span className="infosComptes">Sécurite</span>
                            </div>
                            {/* <img src={francais} className="photoInfos" alt="photoInfos" /> */}
                            <div className="children_form">
                                <label htmlFor="">Votre mot de passe actuel</label>
                                <input type="password" placeholder="yoko" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                {etatEyes?<img src={eyesOpen} alt="" onClick={changesEyes} className="eyes_connexion"/>:<img src ={eyesLock} alt="" onClick={changesEyes} className="eyes_connexion"/>}
                            </div>
                            <div className="children_form">
                                <label htmlFor="">Nouveau mot de passe</label>
                                <input type="password" placeholder="Mike" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                {etatEyes?<img src={eyesOpen} alt="" onClick={changesEyes} className="eyes_connexion"/>:<img src ={eyesLock} alt="" onClick={changesEyes} className="eyes_connexion"/>}
                            </div>
                            <div className="children_form">
                                <label htmlFor="">Confirmer mot de passe</label>
                                <input type="password" placeholder="sm@gmail.com" value={email} onChange={(e) => setEmail(e.target.value)} />
                                {etatEyes?<img src={eyesOpen} alt="" onClick={changesEyes} className="eyes_connexion"/>:<img src ={eyesLock} alt="" onClick={changesEyes} className="eyes_connexion"/>}
                            </div>
                            <div className="parent_button_tribunal1">
                                <span></span>
                                {loading ? (
                                    <button className="button_tribunal_black">En cours ...</button>
                                ) : (
                                    <button className="button_tribunal" type="submit">Enregistrer les modifications</button>
                                )

                                }
                            </div>
                        </form>
                         </div>
                }
                <div className="header_right_right">
                    <img src={tchouta} alt="" className="picture_admin" />
                    <div className="header_right_right_child2">
                        <div className="information_admin" onClick={changeDeconnexion}>
                            <span className="name_admin">Yvan Njanko</span>
                            <img src={chevron} alt="" className="down" />
                        </div>
                        <span className="role_admin">Admin</span>
                    </div>
                </div>
                {
                    compte && (
                        <div className="comptes">
                            <div className="sous_comptes" onClick={checkProfil}>
                                <img src={comptes} alt="" />
                                <span>Gérer le Compte</span>
                            </div>
                            <div className="sous_comptes1" onClick={logout}>
                                <img src={deconnecter} alt="" />
                                <span>Se déconnecter</span>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );
};
